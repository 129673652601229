export enum ButtonTypes {
  PRIMARY,
  PRIMARY_NEW,
  OUTLINE,
  ACCENT,
  LINK,
  CUSTOM,
  DROPDOWN,
}

export enum BtnSizeTypes {
  NORMAL,
  MEDIUM,
  SMALL,
}

export enum DropdownSizeType {
  NORMAL,
  MEDIUM,
  SMALL,
}
