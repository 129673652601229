import React from 'react';
import { BLUE_LOADER } from 'constants/lottie/loading_blue';
import { WHITE_LOADER } from 'constants/lottie/loading_whilte';
import { BtnSizeTypes, ButtonTypes } from 'components/button/types';
import Player from 'components/DynamicLottiePlayer';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: string;
  btnType?: ButtonTypes;
  className?: string;
  isDisabled?: boolean;
  size?: BtnSizeTypes;
  onClick?: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  defaultLoader?: boolean;
}

const buttonStyles = {
  [ButtonTypes.PRIMARY]:
    'tw-bg-TEXT_PRIMARY f-16-400 tw-text-white tw-min-w-fit tw-rounded-full hover:tw-bg-[#393D54] disabled:tw-bg-DIVIDER_GRAY disabled:tw-text-TEXT_TERTIARY',
  [ButtonTypes.PRIMARY_NEW]: 'tw-text-sm f-14-400 tw-rounded-full tw-border tw-border-DIVIDER_GRAY',
  [ButtonTypes.OUTLINE]:
    'tw-border tw-border-DIVIDER_GRAY f-16-400 tw-min-w-fit tw-text-TEXT_GRAY_1 tw-rounded-full enabled:hover:tw-border-TEXT_PRIMARY disabled:tw-bg-BASE_SECONDARY disabled:tw-text-TEXT_TERTIARY',
  [ButtonTypes.ACCENT]:
    'tw-bg-ACCENT_BLUE button-shadow f-16-400 tw-rounded-full tw-text-TEXT_GRAY_1 enabled:hover:tw-bg-ACCENT_BLUE_HOVER enabled:active:tw-shadow-none disabled:tw-bg-DIVIDER_GRAY',
  [ButtonTypes.LINK]: 'f-12-400 tw-bg-transparent tw-text-TEXT_SECONDARY',
  [ButtonTypes.DROPDOWN]:
    'f-20-500 tw-w-60 tw-h-[59px] tw-py-4 tw-px-8 tw-text-ZAMP_PRIMARY tw-bg-BLUE_4 tw-rounded-full',
  [ButtonTypes.CUSTOM]: '',
};

const sizeStyles: any = {
  [BtnSizeTypes.NORMAL]: 'tw-h-14',
  [BtnSizeTypes.MEDIUM]: 'tw-h-11 f-14-400',
  [BtnSizeTypes.SMALL]: 'tw-h-9 f-12-400',
};

const commonButtonStyles = 'tw-px-6 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-transition-all';

const Button: React.FC<ButtonProps> = ({
  className = '',
  btnType = ButtonTypes.PRIMARY,
  isDisabled = false,
  children,
  onClick,
  size = BtnSizeTypes.NORMAL,
  isLoading,
  defaultLoader = true,
  ...rest
}) => (
  <button
    {...rest}
    type='submit'
    onClick={() => {
      if (!isLoading && onClick) onClick();
    }}
    disabled={isDisabled}
    className={`${commonButtonStyles} ${buttonStyles[btnType]} ${sizeStyles[size]} ${className} ${
      isDisabled && 'tw-cursor-not-allowed'
    }`}
  >
    {isLoading ? (
      <Player
        src={JSON.parse(defaultLoader ? WHITE_LOADER : BLUE_LOADER)}
        className='lottie-player'
        loop
        autoplay
        width={20}
        height={20}
      />
    ) : (
      children
    )}
  </button>
);

export default Button;
