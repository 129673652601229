import { MapAny } from 'destiny/dist/types';
import dynamic from 'next/dynamic';

// Dynamically import the Player component
const Player = dynamic(() => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player), {
  ssr: false,
});

const DynamicLottiePlayer = ({ src, autoplay, style = {}, keepLastFrame, ...rest }: MapAny) => {
  return src ? <Player src={src} style={style} autoplay={autoplay} keepLastFrame={keepLastFrame} {...rest} /> : null;
};

export default DynamicLottiePlayer;
