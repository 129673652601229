export const WHITE_LOADER = `{
    "nm": "loading_6",
    "mn": "",
    "layers": [
        {
            "ty": 4,
            "nm": "Shape Layer 2",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 300,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        50,
                        50,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        250,
                        250,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 1,
                    "k": [
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                0
                            ],
                            "t": 0
                        },
                        {
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "s": [
                                360
                            ],
                            "t": 60
                        }
                    ],
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Ellipse 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "el",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Ellipse",
                            "nm": "Ellipse Path 1",
                            "d": 1,
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 3
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    300,
                                    300
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 2,
                            "lj": 1,
                            "ml": 4,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 50,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                },
                {
                    "ty": "tm",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Filter - Trim",
                    "nm": "Trim Paths 1",
                    "ix": 2,
                    "e": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.333,
                                    "y": 0
                                },
                                "i": {
                                    "x": 0.667,
                                    "y": 1
                                },
                                "s": [
                                    1
                                ],
                                "t": 0
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    100
                                ],
                                "t": 50
                            }
                        ],
                        "ix": 2
                    },
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    0
                                ],
                                "t": 0
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    3
                                ],
                                "t": 60
                            }
                        ],
                        "ix": 3
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "o": {
                                    "x": 0.333,
                                    "y": 0
                                },
                                "i": {
                                    "x": 0.667,
                                    "y": 1
                                },
                                "s": [
                                    0
                                ],
                                "t": 10
                            },
                            {
                                "o": {
                                    "x": 0.167,
                                    "y": 0.167
                                },
                                "i": {
                                    "x": 0.833,
                                    "y": 0.833
                                },
                                "s": [
                                    99
                                ],
                                "t": 60
                            }
                        ],
                        "ix": 1
                    },
                    "m": 1
                }
            ],
            "ind": 0
        },
        {
            "ty": 4,
            "nm": "Shape Layer 1",
            "mn": "",
            "sr": 1,
            "st": 0,
            "op": 300,
            "ip": 0,
            "hd": false,
            "cl": "",
            "ln": "",
            "ddd": 0,
            "bm": 0,
            "tt": 0,
            "hasMask": false,
            "td": 0,
            "ao": 0,
            "ks": {
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        50,
                        50,
                        100
                    ],
                    "ix": 6
                },
                "sk": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [
                        250,
                        250,
                        0
                    ],
                    "ix": 2
                },
                "sa": {
                    "a": 0,
                    "k": 0
                },
                "o": {
                    "a": 0,
                    "k": 30,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                }
            },
            "ef": [],
            "shapes": [
                {
                    "ty": "gr",
                    "bm": 0,
                    "cl": "",
                    "ln": "",
                    "hd": false,
                    "mn": "ADBE Vector Group",
                    "nm": "Ellipse 1",
                    "ix": 1,
                    "cix": 2,
                    "np": 3,
                    "it": [
                        {
                            "ty": "el",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Shape - Ellipse",
                            "nm": "Ellipse Path 1",
                            "d": 1,
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 3
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    300,
                                    300
                                ],
                                "ix": 2
                            }
                        },
                        {
                            "ty": "st",
                            "bm": 0,
                            "cl": "",
                            "ln": "",
                            "hd": false,
                            "mn": "ADBE Vector Graphic - Stroke",
                            "nm": "Stroke 1",
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 50,
                                "ix": 5
                            },
                            "d": [],
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            }
                        },
                        {
                            "ty": "tr",
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            }
                        }
                    ]
                }
            ],
            "ind": 1
        }
    ],
    "ddd": 0,
    "h": 500,
    "w": 500,
    "meta": {
        "a": "",
        "k": "",
        "d": "",
        "g": "@lottiefiles/toolkit-js 0.17.4",
        "tc": "#ffffff"
    },
    "v": "5.8.1",
    "fr": 30,
    "op": 61,
    "ip": 0,
    "assets": []
}`;
